import React from 'react'
import Button from '../../../../common/Button'
import * as styles from '../assets/styles/controlBar'
import { lightButton } from '../assets/styles/general'

// need times, current day, etc., + calendar widgety thingy
const ControlBar = ({ saveAction, cancelAction, addStyles, saveLock, saveText }) => {
  let barStyles = styles.bar
  if (addStyles) {
    barStyles = { ...styles.bar, ...addStyles }
  }
  if (saveLock) {
    saveAction = function () {}
  }
  const saveLabel = saveText || 'Save'
  return (
    <div className="control-bar" style={barStyles}>
      <Button testId="sr-button-save" label={saveLabel} style={styles.buttons} onClick={saveAction} />
      <Button label="Cancel" testId="sr-button-cancel" style={{ ...styles.buttons, ...lightButton }} onClick={cancelAction} />
    </div>
  )
}

export default ControlBar
