import { Map, fromJS } from 'immutable'
import { LOAD_SEATING_AREAS_SUCCESS } from '../actions/ActionTypes'

const initialState = fromJS({
  seating_areas: [],
})

const seatingAreas = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEATING_AREAS_SUCCESS:
      return state.set('seating_areas', action.seatingAreas)
    default:
      return state
  }
}

export default seatingAreas
