import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import App from './containers/App'
import eventManager from './reducers/combineReducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(eventManager, composeEnhancers(applyMiddleware(thunk)))

const venueId = document.getElementById('_venue_id').value

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <Component venueId={venueId} />
      </AppContainer>
    </Provider>,
    document.getElementById('event-manager')
  )
}

render(App)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App)
  })
}
