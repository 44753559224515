import priceFormatter from 'currency-formatter'
import dateformat from 'dateformat'
import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import Switch from '../../../../common/Switch'
import { actionEdit, actionList, actionDelete, actionClone, actionEventLink } from '../assets/styles/buttons'
import * as styles from '../assets/styles/eventList'
import { shadow, areYouSure, sureControls } from '../assets/styles/general'
import { getRecurs } from '../utils/time'
import ControlBar from './ControlBar'
import Header from './Header'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Box } from '@sevenrooms/react-components/components/Box'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const EventList = ({
  onListClick,
  showRecurs,
  eventList,
  isLoading,
  addEvent,
  venueId,
  deleteEventConfirm,
  deleteEvent,
  cloneEvent,
  linkEvent,
  excludeDateHandler,
  ux,
}) => {
  const data = eventList.toJS()
  const uxdata = ux.toJS()
  const venue_today = document.getElementById('today_date_url').value
  const venue_today_moment = moment(venue_today)
  const { venueUrlKey } = useAppContext()

  const recurRow = (recur, key) => {
    const dateFix = {
      background: 'none',
      borderBottom: 'none',
      borderRadius: 0,
    }

    const cellFix = {
      borderRadius: 0,
      borderRight: 'none',
      borderLeft: 'none',
      borderTop: 'none',
      color: '#999',
      fontSize: '14px',
      fontStyle: 'italic',
      paddingTop: 0,
    }
    // add recur key when real data happens
    return (
      <tr key={`event-recur-${key}`}>
        <td style={{ ...styles.dateBody, ...dateFix, ...cellFix }} />
        <td style={{ ...styles.nameBody, ...cellFix }}>{dateFormat(new Date(recur.event_date), 'dddd, mmmm dS, yyyy')}</td>
        <td style={{ ...styles.ticketBody, ...cellFix }}>
          {priceFormatter.format(recur.tickets_sold, {
            thousand: ',',
            precision: 0,
          })}
          /
          {priceFormatter.format(recur.tickets_total, {
            thousand: ',',
            precision: 0,
          })}
        </td>
        <td style={{ ...styles.revenueBody, ...cellFix }}>{priceFormatter.format(recur.revenue, { code: uxdata.currencyCode })}</td>
        <td style={{ ...styles.hiddenText, ...cellFix }} />
        <td style={{ ...styles.actionsBody, ...cellFix }}>
          {!recur.hide_exclude_control && moment(recur.event_date) >= venue_today_moment ? (
            <Switch
              name="hide_availability"
              toggle={_.partial(excludeDateHandler, recur.event_id, recur.event_date)}
              value={recur.hidden}
            />
          ) : null}
        </td>
      </tr>
    )
  }

  const loadRow = item => {
    let dateStyle = styles.dateBody
    let { listRow } = styles

    if (item.hide_event) {
      listRow = { ...listRow, background: '#f5f5f5' }
    }
    if (item.is_past) {
      dateStyle = { background: '#f5f5f5', ...styles.dateBody }
    } else if (item.is_recurring) {
      dateStyle = { background: '#cedffe', ...styles.dateBody }
    }
    return (
      <tr data-test="sr-row-event_list_item" key={`event-list-${item.id}`} style={listRow}>
        <td style={dateStyle}>
          <span style={styles.date}>{item.display_day}</span>
          <span style={styles.month}>{item.display_month}</span>
        </td>
        <td style={styles.nameBody} onClick={item.is_past ? null : onListClick.bind(null, item.id)}>
          <h4 style={styles.name}>{item.name}</h4>
          <p style={styles.time}>{item.time_display}</p>
        </td>
        <td style={styles.ticketBody}>{item.is_recurring ? '' : item.has_sales_data_loaded ? item.ticket_display : ''}</td>
        <td style={styles.revenueBody}>
          {item.is_recurring
            ? ''
            : item.has_sales_data_loaded
            ? priceFormatter.format(item.sales_data.revenue, {
                code: uxdata.currencyCode,
              })
            : ''}
        </td>
        <td style={styles.hiddenText}>{item.hide_event ? 'Hidden' : ''}</td>
        <td style={styles.actionsBody}>
          <ReactTooltip id={`tip-${item.id}`} effect="solid" />
          {item.is_recurring && _.size(item.sales_data.sales) ? (
            <p
              data-for={`tip-${item.id}`}
              data-tip="Recurring events"
              data-test="sr-button-show_recurring"
              style={actionList}
              onClick={showRecurs.bind(null, item.id)}
            />
          ) : null}

          <p
            data-for={`tip-${item.id}`}
            data-tip="Clone event"
            style={actionClone}
            data-test="sr-button-clone_event"
            onClick={cloneEvent.bind(null, item.id)}
          />

          <p
            data-for={`tip-${item.id}`}
            data-tip="Copy event link"
            style={actionEventLink}
            data-test="sr-button-copy_event"
            data-link={item.short_url}
            onClick={linkEvent.bind(null, item.id, item.short_url)}
          />

          {item.is_past ? null : (
            <span>
              <p
                data-for={`tip-${item.id}`}
                data-tip="Edit settings"
                style={actionEdit}
                data-test="sr-button-edit_event_setting"
                onClick={onListClick.bind(null, item.id)}
              />
              {_.isEmpty(item.sales_data.sales) || _.some(item.sales_data.sales.map(s => s.tickets_sold)) ? null : (
                <p
                  data-for={`tip-${item.id}`}
                  data-tip="Delete event"
                  data-test="sr-button-delete_event"
                  style={actionDelete}
                  onClick={deleteEventConfirm.bind(null, item.id)}
                />
              )}
            </span>
          )}
        </td>
      </tr>
    )
  }

  let deleteTitle = ''

  const buildList = () => {
    // load these with corresponding ids
    const itemRender = []

    for (const item of data) {
      const time_range = `${item.start_time}-${item.end_time}`
      item.has_sales_data_loaded = !_.isEmpty(item.sales_data)
      if (!item.is_recurring) {
        const _d = new Date(item.start_date)
        item.display_day = _d.getDate()
        item.display_month = dateFormat(_d, 'mmm')
        item.time_display = time_range
        item.ticket_display = item.has_sales_data_loaded
          ? `${priceFormatter.format(item.sales_data.tickets_sold, {
              thousand: ',',
              precision: 0,
            })}/${priceFormatter.format(item.sales_data.tickets_total, {
              thousand: ',',
              precision: 0,
            })}`
          : ''
      } else {
        item.display_day = getRecurs(item.recurring_type, item.start_date, item.end_date, item.day_of_week)
        item.display_month = 'Dates'
        let _t = 'Repeat '
        if (item.recurring_type == 'DAILY') {
          _t += 'daily '
        } else if (item.recurring_type == 'WEEKLY') {
          _t += `weekly on ${item.day_of_week.join(', ')}`
        }
        item.time_display = `${_t} ${time_range} from ${moment(item.start_date).format('MMM Do, YYYY')} to ${moment(item.end_date).format(
          'MMM Do, YYYY'
        )}`
        item.ticket_display = item.has_sales_data_loaded
          ? priceFormatter.format(item.sales_data.tickets_sold, {
              thousand: ',',
              precision: 0,
            })
          : ''
      }

      itemRender.push(loadRow(item))
      if (item.is_recurring && item.show_recurs && _.size(item.sales_data.sales)) {
        itemRender.push(item.sales_data.sales.map(recurRow))
      }

      if (item.id == uxdata.deletingEvent) {
        deleteTitle = item.name
      }
    }

    return itemRender
  }
  return (
    <div>
      <Header
        title="Events"
        actions={
          <ThemeProvider theme={vmsTheme}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} margin={1} gap={3}>
              <Button
                variant="outlined"
                onClick={() => window.open(`${window.location.origin}/events/${venueUrlKey}`, '_blank')}
                data-test="sr-button-view_event_widget"
              >
                View Event Widget
              </Button>
              <Button variant="contained" onClick={_.partial(addEvent, venueId)} data-test="sr-button-add_event">
                Add Event
              </Button>
            </Box>
          </ThemeProvider>
        }
      />
      <div style={styles.wrap}>
        {data.length ? (
          <table id="events" style={styles.table}>
            <thead>
              <tr>
                <th style={styles.dateHead}>Date</th>
                <th style={styles.nameHead}>Event</th>
                <th style={styles.ticketHead}># Sold</th>
                <th style={styles.revenueHead}>Revenue</th>
                <th style={styles.actionsHead} />
              </tr>
            </thead>
            <tbody>{buildList()}</tbody>
          </table>
        ) : isLoading ? null : (
          <p style={styles.emptyEvents}>You have no events set up.</p>
        )}
      </div>
      {uxdata.deletingEvent ? (
        <div>
          <div style={shadow} />
          <div style={areYouSure}>
            Are you sure you want to delete the event <em>{deleteTitle}</em>?
            <ControlBar
              cancelAction={deleteEventConfirm.bind(null, null)}
              saveAction={deleteEvent.bind(null, uxdata.deletingEvent)}
              saveText="Delete"
              addStyles={sureControls}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default EventList
