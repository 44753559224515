import { brandColor } from './general'

export const floatClear = {
  float: 'left',
  clear: 'both',
}

export const eventName = {
  width: '554px',
}

export const customPolicies = {
  width: '723px',
  boxSizing: 'border-box',
  height: '75px',
}

export const dateTime = {
  float: 'left',
}

export const date = {
  clear: 'none',
  float: 'left',
}

export const linkCss = {
  color: brandColor,
}

export const inputDate = {
  width: '160px',
  backgroundPosition: 'right 7px top 7px',
  backgroundSize: 15,
}

export const time = {
  width: '100px',
  clear: 'none',
  float: 'left',
  margin: '28px 20px 0 5px',
}

export const inputTime = {
  width: '100px',
  padding: 6,
}

export const timeActions = {
  clear: 'both',
  color: brandColor,
  padding: '5px 0',
}

const actionLink = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  backgroundPosition: '0 1px',
  cursor: 'pointer',
  fontSize: 10,
  marginRight: 10,
  paddingLeft: 13,
}

export const recurringLink = { ...actionLink, backgroundImage: `url(${MEDIA_URL}images/new-calendar-icon-blue.png)` }

export const adjusttimeLink = { ...actionLink, backgroundImage: `url(${MEDIA_URL}images/clock-icon.png)` }

export const dateWrapper = {
  clear: 'both',
  paddingTop: 20,
}

export const rtWrapper = {
  clear: 'both',
  padding: '20px 0',
  width: 723,
}

export const switchLabel = {
  color: '#999',
  float: 'left',
  fontSize: '12px',
  lineHeight: '25px',
  marginLeft: 10,
}
