import _ from 'lodash'
import {
  ADD_INVENTORY,
  DELETE_INVENTORY,
  LOAD_INVENTORY,
  SAVE_INVENTORY,
  SAVE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_CONFIRM,
  UPDATE_INVENTORY,
  CLONE_INVENTORY,
  TOGGLE_UPSELLS_DISPLAY,
  SELECT_UPSELL,
  LOAD_UPSELL,
  CHANGE_INVENTORY_BOOKING_POLICY_TYPE,
  CHANGE_INVENTORY_BOOKING_POLICY,
  CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE,
  CHANGE_INVENTORY_CANCELLATION_POLICY,
  CHANGE_SELECTED_TAGS,
} from './ActionTypes'
import { loadEvent } from './events'
import { requestSaveInventory, requestDeleteInventory } from './services'

export const loadInventory = id => (dispatch, getState) => {
  if (id) {
    const state = getState()
    const currentEvent = _.find(state.eventListData.toJS(), {
      id: state.currentEvent.id,
    })
    const currentInventory = _.find(currentEvent.inventories, { id })
    dispatch({
      type: LOAD_UPSELL,
      selectedUpsells: currentInventory.upsell_categories,
    })
  }
  return dispatch({
    type: LOAD_INVENTORY,
    id,
  })
}

export const saveInventory = (venueId, eventId, inventoryId) => {
  const validator = new sr.Validator('#inventory-editor')

  if (!validator.validate()) {
    return { type: 'FAIL' }
  }

  return (dispatch, getState) => {
    const state = getState()
    const currentEvent = _.find(state.eventListData.toJS(), { id: eventId })
    const currentInventory = _.find(currentEvent.inventories, { id: inventoryId })

    const currentUpsells = state.upsells.get('addUpsell') ? state.upsells.get('selectMap').toJS() : []
    return requestSaveInventory(currentEvent, currentInventory, currentUpsells, state).then(inventoryData => {
      if (inventoryData.error) {
        dispatch(loadEvent(eventId))
        dispatch(loadInventory(null))
      } else {
        dispatch(loadEvent(eventId))
        dispatch(saveInventorySuccess(eventId, inventoryData.id))
        dispatch(loadInventory(null))
      }
    })
  }
}

export const saveInventorySuccess = (id, inventoryId) => ({ type: SAVE_INVENTORY_SUCCESS, id, inventoryId })

export const addInventory = id => ({ type: ADD_INVENTORY, id })

export const deleteInventoryConfirm = id => ({ type: DELETE_INVENTORY_CONFIRM, id })

export const deleteInventory = (id, inventoryId) => (dispatch, getState) => {
  const state = getState()
  const currentEvent = _.find(state.eventListData.toJS(), { id })
  const currentInventory = _.find(currentEvent.inventories, { id: inventoryId })
  return currentInventory
    ? requestDeleteInventory(currentInventory).then(response =>
        response.error ? dispatch(loadEvent(currentEvent.id)) : dispatch(deleteInventorySuccess(id, inventoryId))
      )
    : dispatch(deleteInventorySuccess(id, inventoryId))
}

export const deleteInventorySuccess = (id, inventoryId) => ({ type: DELETE_INVENTORY_SUCCESS, id, inventoryId })

export const cloneInventory = (id, inventoryId) => ({ type: CLONE_INVENTORY, id, inventoryId })

export const updateInventory = (id, inventoryId, e) => {
  const field = e.target.name
  let { value } = e.target
  if (field === 'tax_group_id' && !value) {
    value = null
  }
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const handleFee = (id, inventoryId, e) => {
  const field = e.target.name
  const value = e.target.id === 'absorb_fee'
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const handleRadioUpdate = (id, inventoryId, e) => {
  const field = e.target.name
  const value = e.target.id
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const handleAdditionalFee = (id, inventoryId, e) => {
  const field = e.target.name
  const value = e.target.checked
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const handleAdditionalFeeTax = (id, inventoryId, e, taxGroups) => dispatch => {
  const field = e.target.name
  const value = e.target.checked
  const tax_group_id = Object.keys(taxGroups)[0]
  dispatch({
    type: UPDATE_INVENTORY,
    id,
    inventoryId,
    field: 'additional_fee_tax_id',
    value: tax_group_id,
  })

  dispatch({ type: UPDATE_INVENTORY, id, inventoryId, field, value })
}

export const checkHandler = (id, inventoryId, e) => {
  const field = e.target.name
  const value = e.target.checked
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const tieringCheckHandler = (id, inventoryId, e) => {
  const field = 'inventory_tier'
  const value = e.target.checked
  const toggledInventory = e.target.name.replace('tier-item-', '')
  return {
    type: UPDATE_INVENTORY,
    id,
    inventoryId,
    field,
    value,
    toggledInventory,
  }
}

export const taxCheckHandler = (id, inventoryId, e, taxGroups) => (dispatch, getState) => {
  const field = e.target.name
  const value = e.target.checked
  const tax_group_id = Object.keys(taxGroups)[0]
  dispatch({ type: UPDATE_INVENTORY, id, inventoryId, field, value })
  dispatch({
    type: UPDATE_INVENTORY,
    id,
    inventoryId,
    field: 'tax_group_id',
    value: tax_group_id,
  })
}

export const toggleHidden = (id, inventoryId, value) => {
  const field = 'hide_inventory'
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const toggleGuestCheckout = (id, inventoryId, value) => {
  const field = 'guest_checkout'
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const toggleEnablePromocodes = (id, inventoryId, value) => {
  const field = 'enable_promocodes'
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value }
}

export const toggleTiering = (id, inventoryId, value, eventInventories) => {
  const field = 'hide_tiering'
  const inventories = eventInventories.filter(inventory => inventory.id !== inventoryId)
  return { type: UPDATE_INVENTORY, id, inventoryId, field, value, inventories }
}

export const toggleUpsellsDisplay = value => ({
  type: TOGGLE_UPSELLS_DISPLAY,
  value,
})

export const changeInventoryBookingPolicyType = (id, inventoryId, e) => (dispatch, getState) => {
  const defaultBookingPolicy = getState().venueConfig.default_booking_policy
  dispatch(changeInventoryBookingPolicyTypeWithDefault(id, inventoryId, e, defaultBookingPolicy))
}

export const changeInventoryBookingPolicyTypeWithDefault = (id, inventoryId, e, defaultBookingPolicy) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_INVENTORY_BOOKING_POLICY_TYPE,
    id,
    inventoryId,
    field,
    value,
    defaultBookingPolicy,
  }
}

export const changeInventoryBookingPolicy = (id, inventoryId, e) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_INVENTORY_BOOKING_POLICY,
    id,
    inventoryId,
    field,
    value,
  }
}

export const changeInventoryCancellationPolicyType = (id, inventoryId, e) => (dispatch, getState) => {
  const defaultCancellationPolicy = getState().venueConfig.default_cancellation_policy
  dispatch(changeInventoryCancellationPolicyTypeWithDefault(id, inventoryId, e, defaultCancellationPolicy))
}

export const changeInventoryCancellationPolicyTypeWithDefault = (id, inventoryId, e, defaultCancellationPolicy) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE,
    id,
    inventoryId,
    field,
    value,
    defaultCancellationPolicy,
  }
}

export const changeInventoryCancellationPolicy = (id, inventoryId, e) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_INVENTORY_CANCELLATION_POLICY,
    id,
    inventoryId,
    field,
    value,
  }
}

export const changeSelectedTags = (id, inventoryId, value) => ({
  type: CHANGE_SELECTED_TAGS,
  id,
  inventoryId,
  value,
})

export const selectUpsell = selectMap => ({ type: SELECT_UPSELL, selectMap })
