import { fromJS } from 'immutable'
import _ from 'lodash'
import { LOAD_EVENTS_SUCCESS, SELECT_UPSELL, TOGGLE_UPSELLS_DISPLAY, ADD_INVENTORY, LOAD_UPSELL } from '../actions/ActionTypes'

const initialState = fromJS({
  entities: {},
  selectMap: {},
  addUpsell: false,
})

const upsells = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EVENTS_SUCCESS: {
      return state.merge({ entities: action.upsells || {} })
    }
    case SELECT_UPSELL:
      return state.merge({ selectMap: action.selectMap })
    case TOGGLE_UPSELLS_DISPLAY:
      return state.set('addUpsell', action.value)
    case ADD_INVENTORY:
      return state.merge({ selectMap: deriveUpsellsSelectMap(state.get('entities').toJS()) })
    case LOAD_UPSELL: {
      const selectMap = deriveUpsellsSelectMap(state.get('entities').toJS(), action.selectedUpsells)
      const addUpsell = _.some(selectMap, upsell => upsell.isSelected)
      return state.merge({ selectMap, addUpsell })
    }
    default:
      return state
  }
}

function deriveUpsellsSelectMap(upsellEntities, selectedUpsells) {
  return _.reduce(
    upsellEntities,
    (result, upsell, upsellId) => {
      const label = upsell.is_required ? `${upsell.name} (required)` : upsell.name
      const isSelected = selectedUpsells && selectedUpsells.includes(upsellId)
      return _.assign(result, { [upsellId]: { label, isSelected } })
    },
    {}
  )
}

export default upsells
