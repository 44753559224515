// Styles for recurrence modal

export const dtAdjustment = {
  margin: '10px 20px 0 0',
}

export const content = {
  clear: 'both',
  float: 'left',
  padding: '17px 0 10px',
}

export const dow = {
  clear: 'both',
  float: 'left',
  padding: '10px 0 30px',
}

export const dowItem = {
  clear: 'none',
  marginRight: 20,
}

export const dowLabel = {
  margin: '25px 0 0 -5px',
  textAlign: 'center',
  width: 19,
}

export const times = {
  clear: 'both',
}

export const dates = {
  clear: 'both',
}

export const calced = {
  clear: 'both',
  color: '#aaa',
  fontSize: 11,
  fontStyle: 'italic',
  height: 30,
  lineHeight: '30px',
}

export const controls = {
  borderTop: '1px solid #dadada',
  padding: 6,
}

export const recurButtons = {
  float: 'right',
  margin: '0 0 5px 5px',
  width: 60,
}
