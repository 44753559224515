import { srDelete, srGet, srPost, srPostJson, srPut } from '@sevenrooms/core/api'

export const createExperience = (experience, venueId) => {
  const path = `/api-yoa/experiences`
  return srPut(
    path,
    {
      experience,
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw new Error('Failed to save')
    }
    return response.data || {}
  })
}

export const saveExperience = (experience, venueId, experienceId) => {
  const path = `/api-yoa/experiences/${experienceId}`
  return srPost(
    path,
    {
      experience,
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw new Error('Failed to save')
    }
    return response.data || {}
  })
}

export const deleteExperience = (venueId, experienceId) => {
  const path = `/api-yoa/experiences/${experienceId}`
  return srDelete(
    path,
    {
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw new Error('Failed to delete')
    }
    return response.data || {}
  })
}

export const getExperiences = (venueId, params) =>
  srGet(
    `/api-yoa/experiences`,
    { ...params, venue: venueId },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => e)

export const patchExperiences = (experiences, venueId) => {
  const path = `/api-yoa/experiences?venue_id=${venueId}`
  return srPostJson(
    path,
    {
      experiences,
    },
    true
  ).then(response => {
    if (response.error) {
      throw new Error('Failed to save')
    }
    return response.data || {}
  })
}
