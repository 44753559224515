// For the rtf editor

export const editorWrap = {
  border: '1px solid #dadada',
}

export const controls = {
  background: '#f5f5f5',
  borderBottom: '1px solid #dadada',
  height: 25,
  padding: '0 1px',
}

export const editor = {
  fontSize: 14,
  padding: 6,
}

const button = {
  cursor: 'pointer',
  display: 'inline-block',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  height: 21,
  margin: '2px 1px',
  width: 18,
}

// Somebody's getting a talking to about standard icon file formatting

export const bold = { ...button, backgroundSize: '9px 12px', backgroundImage: `url(${MEDIA_URL}images/eventmanager/bold.png)` }

export const italic = { ...button, backgroundSize: '2px 11px', backgroundImage: `url(${MEDIA_URL}images/eventmanager/italic.png)` }

export const underline = {
  ...button,
  backgroundSize: '8px 12px',
  backgroundImage: `url(${MEDIA_URL}images/eventmanager/underline.png)`,
}

export const bullets = {
  ...button,
  backgroundSize: '14px 12px',
  backgroundImage: `url(${MEDIA_URL}images/eventmanager/bullet_list.png)`,
}

export const numbers = {
  ...button,
  backgroundSize: '11px 12px',
  backgroundImage: `url(${MEDIA_URL}images/eventmanager/number_list.png)`,
}

export const link = { ...button, backgroundSize: '15px 12px', backgroundImage: `url(${MEDIA_URL}images/eventmanager/link.png)` }

export const unlink = { ...button, backgroundSize: '15px 15px', backgroundImage: `url(${MEDIA_URL}images/eventmanager/unlink.png)` }

export const divider = {
  background: '#dadada',
  display: 'inline-block',
  height: '100%',
  margin: '0 6px',
  width: 1,
}

export const urlEditor = {
  background: '#f7f7f7',
  padding: 10,
}

export const urlText = {
  color: '#777',
  fontStyle: 'italic',
}

export const urlInput = {
  background: '#fff',
  border: '1px solid #ccc',
  marginLeft: 6,
  padding: '3px 2px',
  width: 400,
}

const urlButton = {
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '11px',
  lineHeight: '23px',
  margin: '0 2px',
  position: 'relative',
  textAlign: 'center',
  top: '-1px',
  width: '50px',
}

export const saveUrl = { ...urlButton, background: '#347BAF', borderRadius: '3px', color: '#fff', marginLeft: '15px' }

export const cancelUrl = { ...urlButton, color: '#777' }

export const rteInput = {
  overflow: 'scroll',
}

export const linkText = {
  color: '#347BAF',
}
