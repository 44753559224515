const _width = 80 // %

export const floatClear = {
  float: 'left',
  clear: 'both',
}

export const customPolicies = {
  width: '723px',
  boxSizing: 'border-box',
  height: '75px',
}

export const modal = {
  background: '#fff',
  height: '90%',
  left: '50%',
  marginLeft: `${-(_width / 2)}%`,
  position: 'fixed',
  top: '5%',
  width: `${_width}%`,
  overflow: 'hidden',
  zIndex: 1001,
}

export const closer = {
  cursor: 'pointer',
  fontSize: 41,
  fontWeight: 100,
  position: 'absolute',
  right: 10,
  top: 0,
}

export const modalPosition = {
  left: '50%',
  marginLeft: `${-(_width / 2)}%`,
  bottom: '5%',
  marginRight: '1%',
  width: `${_width - 2}%`,
}

export const content = {
  padding: '20px 20px 120px',
  overflow: 'hidden',
  height: '68%',
  overflowY: 'scroll',
}

export const rowItems = {
  clear: 'none',
  marginRight: 10,
}

export const eg = {
  clear: 'both',
  color: '#aaa',
  float: 'left',
  fontSize: 10,
  fontStyle: 'italic',
}

export const calced = {
  clear: 'both',
  color: '#aaa',
  fontSize: 11,
  paddingTop: 4,
}

export const follow = {
  color: '#aaa',
  float: 'left',
  fontSize: 11,
  fontStyle: 'italic',
  marginTop: 39,
  paddingLeft: 10,
}

export const basicInfo = {
  clear: 'both',
  padding: '10px 0',
  width: 800,
}

export const payments = {
  clear: 'both',
  float: 'left',
  padding: '20px 0 0 0',
  width: 800,
}

export const taxCol = {
  float: 'left',
  width: 220,
}

export const adminFeeCol = {
  float: 'left',
  width: 230,
  marginLeft: -40,
}

export const additionalPaymentCol = {
  clear: 'both',
  width: 350,
}

export const calculationColumn = {
  float: 'left',
  width: 350,
  top: 0,
  color: '#878787',
  fontSize: 12,
}

export const total = {
  clear: 'both',
  float: 'left',
  paddingTop: 20,
}

export const availTimes = {
  clear: 'both',
  padding: '10px 0',
}

export const timeDivide = {
  color: '#878787',
  float: 'left',
  fontSize: 12,
  margin: '35px 10px 0 0',
}
