import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uploadPhoto, loadCropper, uploadPhotoPreview } from '../actions/events'

class Dropper extends Component {
  constructor(props) {
    super(props)

    this.onDrop = this.onDrop.bind(this)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  onDrop(accepted) {
    // this.props.uploadPhotoPreview(this.props.eventId, accepted, this.props.id);
    // this.props.loadCropper(this.props.eventId, accepted, this.props.id);
    this.props.uploadPhoto(this.props.eventId, accepted, this.props.id)
  }

  render() {
    // active style never goes away for some reason. look into it.
    const active = { ...this.props.style, backgroundColor: '#fff' }

    return (
      <div id="drop-interface">
        <Dropzone style={this.props.style} onDrop={this.onDrop} multiple={false} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
})

const mapDispatchToProps = dispatch => ({
  uploadPhoto: (eventId, accepted, rejected, id) => {
    dispatch(uploadPhoto(eventId, accepted, rejected, id))
  },
  loadCropper: (eventId, accepted, id) => {
    dispatch(loadCropper(eventId, accepted, id))
  },
  uploadPhotoPreview: (eventId, accepted, id) => {
    dispatch(uploadPhotoPreview(eventId, accepted, id))
  },
})

Dropper = connect(mapStateToProps, mapDispatchToProps)(Dropper)

export default Dropper
