import { headerStyle, headerTextStyle } from '../assets/styles/general'
import type { ReactNode, CSSProperties } from 'react'

interface HeaderProps {
  title: string
  actions?: ReactNode
}

export default function Header({ title, actions }: HeaderProps) {
  return (
    <div id="content-header" style={headerStyle as CSSProperties}>
      <h2 style={headerTextStyle as CSSProperties}>{title}</h2>
      {actions}
    </div>
  )
}
