import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleRecurrence, updateEvent, updateDays } from '../actions/events'
import RecurringEvent from '../components/RecurringEvent'

class Recurrence extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <RecurringEvent
        eventId={this.props.eventId}
        eventListData={this.props.eventListData}
        updateEvent={this.props.updateEvent}
        updateDays={this.props.updateDays}
        timeOptions={this.props.timeOptions}
        updateDate={this.props.updateDate}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  eventListData: state.eventListData.toJS(),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateEvent: e => {
    dispatch(updateEvent(ownProps.eventId, e))
  },
  updateDays: e => {
    dispatch(updateDays(ownProps.eventId, e))
  },
  updateDate: (m, name) => {
    // hackin around
    const e = {
      target: {
        name,
        value: m.format('MM/DD/YYYY'),
      },
    }
    dispatch(updateEvent(ownProps.eventId, e))
  },
})

Recurrence = connect(mapStateToProps, mapDispatchToProps)(Recurrence)

export default Recurrence
