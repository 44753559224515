import { Map, fromJS } from 'immutable'
import { IS_LOADING_STATE } from '../actions/ActionTypes'

const initialState = fromJS({
  isLoading: false,
})

const isLoading = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING_STATE:
      return state.set('isLoading', action.stateVal)
    default:
      return state
  }
}

export default isLoading
