import moment from 'moment-timezone'
import React from 'react'
import DatePicker from 'react-datepicker'
import '../assets/styles/react-datepicker.css'

const DateWrapper = ({ onChange, label, rawDate, styles, name }) => {
  const mDate = moment(rawDate)
  const minDate = moment()
  const maxDate = moment().add(1, 'year')
  const id = `id_${name}`
  const divClass = `form-element text ${name}`

  return (
    <div className={divClass} style={styles}>
      <label htmlFor={id}>
        <p className="label">{label}</p>
        <div className="input react-datepicker-wrapper">
          <DatePicker
            id={id}
            className="cal-input evil-cal-fix"
            name={name}
            selected={mDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={m => {
              onChange(m, name)
            }}
          />
        </div>
      </label>
    </div>
  )
}

export default DateWrapper
