import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDateTimeOptions } from '../../../../common/DateTime'
import { loadEvent, loadInitialData, excludeDateHandler } from '../actions/events'
import Main from '../components/Main'

class App extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.timeOptions = getDateTimeOptions()
  }

  componentWillUnmount() {}

  componentDidMount() {
    const selectedEventId = new URL(window.location.href).searchParams.get('selected_event_id')
    this.props.dispatch(loadInitialData(this.props.venueId, selectedEventId)) // this triggers the api call and updates the redux store.
  }

  render() {
    return (
      <Main
        venueId={this.props.venueId}
        eventId={this.props.eventId}
        inventoryId={this.props.inventoryId}
        loadEvent={this.props.loadEvent}
        isLoading={this.props.isLoading}
        excludeDateHandler={this.props.excludeDateHandler}
        timeOptions={this.timeOptions}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  inventoryId: state.inventoryId,
  isLoading: state.isLoading,
})

const mapDispatchToProps = dispatch => ({
  loadEvent: id => {
    dispatch(loadEvent(id))
  },
  excludeDateHandler: (id, dt, name, displayValue) => {
    dispatch(excludeDateHandler(id, dt, name, displayValue))
  },
  dispatch,
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
