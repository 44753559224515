import {
  Editor,
  EditorState,
  Entity,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  CompositeDecorator,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import React from 'react'
import RichTextEditor from 'react-rte'
import * as styles from '../assets/styles/richtext'

class RichText extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)

    let value = null
    if (this.props.content) {
      value = RichTextEditor.createValueFromString(this.props.content, 'html')
    } else {
      value = RichTextEditor.createEmptyValue()
    }

    this.state = {
      value,
    }
  }

  onChange(value) {
    this.setState({ value })
    if (this.props.onChange) {
      this.props.onChange(value.toString('html'))
    }
  }

  render() {
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],

      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
      ],

      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
      ],
    }

    return (
      <div data-test={this.props.testId} style={this.props.defaultStyle}>
        <RichTextEditor value={this.state.value} onChange={this.onChange} toolbarConfig={toolbarConfig} />
      </div>
    )
  }
}

export default RichText
