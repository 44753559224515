import { LOAD_EVENT, SAVE_EVENT_SUCCESS, RESET_EVENT } from '../actions/ActionTypes'

const initialState = {
  id: null,
  original: null,
}

// Temp default
const currentEvent = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EVENT:
    case SAVE_EVENT_SUCCESS:
      return {
        ...state,
        id: action.id,
        original: action.event,
      }
    case RESET_EVENT:
      return {
        ...state,
        id: null,
        original: null,
      }
    default:
      return state
  }
}

export default currentEvent
