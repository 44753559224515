const cell = {
  border: 'none',
  textAlign: 'left',
}

const head = { ...cell, fontWeight: 'bold', padding: 10, textTransform: 'uppercase' }

const body = { ...cell, borderBottom: '1px solid #ccc', padding: 10, borderTop: '1px solid #ccc' }

const widths = {
  date: { width: 20 },
  name: { width: 200 },
  ticket: { width: 100 },
  revenue: { width: 100 },
  actions: { width: 200 },
}

export const wrap = {
  clear: 'both',
  margin: 10,
}

export const table = {
  borderSpacing: '0 10px',
  borderCollapse: 'separate',
  width: '100%',
}

export const listRow = {
  borderRadius: '3px',
  margin: 10,
}

export const dateHead = { ...head, ...widths.date, padding: '10px 5px' }

export const nameHead = { ...head, ...widths.name, paddingLeft: 40 }

export const ticketHead = { ...head, ...widths.ticket }

export const revenueHead = { ...head, ...widths.revenue }

export const actionsHead = { ...head, ...widths.actions }

export const dateBody = {
  ...body,
  ...widths.date,
  borderLeft: '1px solid #ccc',
  borderRight: '1px solid #ccc',
  borderRadius: '3px 0 0 3px',
  padding: '10px 5px',
  textAlign: 'center',
}

const largeText = '18px'
const smallText = '11px'

export const date = {
  display: 'block',
  fontFamily: 'Roboto',
  fontSize: largeText,
}

export const month = {
  display: 'block',
  color: '#999',
  textTransform: 'uppercase',
  fontSize: smallText,
}

export const nameBody = { ...body, ...widths.name, cursor: 'pointer', overflow: 'hidden', paddingLeft: 40, textOverflow: 'ellipsis' }

export const name = {
  fontFamily: 'Roboto',
  fontSize: largeText,
  fontWeight: '400',
  maxWidth: 300,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

export const time = {
  color: '#999',
  fontSize: smallText,
}

export const hiddenText = { ...body, ...widths.ticket, color: '#999', fontSize: largeText }

export const ticketBody = { ...body, ...widths.ticket, color: '#999', fontSize: largeText }

export const revenueBody = { ...body, ...widths.revenue, color: '#999', fontSize: largeText }

export const actionsBody = { ...body, ...widths.actions, borderRight: '1px solid #ccc', borderRadius: '0 3px 3px 0', textAlign: 'right' }

export const emptyEvents = {
  fontSize: '14px',
  fontStyle: 'italic',
  padding: '20px 0',
}
