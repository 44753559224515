import moment from 'moment-timezone'

export const getRecurs = (type, start, finish, days) => {
  start = moment(start)
  finish = moment(finish)

  let count = 0
  const diff = finish.diff(start)

  if (type == 'DAILY' || days.length == 7) {
    count = Math.ceil(moment.duration(diff).asDays() + 1)
  } else if (type == 'WEEKLY') {
    const dowref = moment.weekdays()
    const dowset = days.map(d => dowref.indexOf(d))

    // I tried doing something mathier
    // here, but wasn't worth it.
    while (start <= finish) {
      if (dowset.indexOf(start.day()) > -1) {
        count += 1
      }
      start.add(1, 'days')
    }
  }
  return count
}
