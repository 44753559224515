import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { loadCropEdit, deletePhoto } from '../actions/events'
import { note } from '../assets/styles/general'
import { photoWrapper, smallPhotoSet } from '../assets/styles/photos'
import BannerUpload from '../components/BannerUpload'
import PhotoUpload from '../components/PhotoUpload'

class Photos extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    const that = this
    const events = this.props.eventListData.toJS().filter(item => item.id == that.props.eventId)
    const _photos = _.size(events) ? events[0].photo_map : []

    const photos = _.range(2, _.size(_photos) + 1).map(index => (
      <PhotoUpload
        photo_id={index}
        key={`pk_${index}`}
        photo={_photos[index]}
        eventId={that.props.eventId}
        clickEdit={that.props.loadCropEdit}
        clickDelete={that.props.deletePhoto}
      />
    ))

    return (
      <div id="photo-interface" style={photoWrapper}>
        <ReactTooltip multiline id="image-sizes" effect="solid" />
        <p className="group-label">Images</p>
        <p style={note}>
          Drag your images here or click to upload files. For best results,&nbsp;
          <span
            data-for="image-sizes"
            data-tip="For your banner image, we recommend uploading<br>a rectangular image that is no smaller than 1400 pixels<br>wide and 700 pixels tall. For your additional images, we<br>recommend using square images that are around<br>1400-2500 pixels square. Note your image must be below 4mb."
            style={{ textDecoration: 'underline', cursor: 'default' }}
          >
            view our image size recommendations
          </span>
          .
        </p>
        {_.size(_photos) ? (
          <BannerUpload photo_id={1} photo={_photos[1]} clickEdit={that.props.loadCropEdit} clickDelete={this.props.deletePhoto} />
        ) : undefined}
        <div style={smallPhotoSet}>{photos}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  eventListData: state.eventListData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deletePhoto: id => {
    dispatch(deletePhoto(ownProps.eventId, id))
  },
  loadCropEdit: id => {
    dispatch(loadCropEdit(ownProps.eventId, id))
  },
})

Photos = connect(mapStateToProps, mapDispatchToProps)(Photos)

export default Photos
