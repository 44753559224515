import { LOAD_VENUE_CONFIG } from '../actions/ActionTypes'

const venueConfig = (state = null, action) => {
  switch (action.type) {
    case LOAD_VENUE_CONFIG:
      return {
        default_booking_policy: action.default_booking_policy,
        default_cancellation_policy: action.default_cancellation_policy,
        min_price_enabled: action.min_price_enabled,
        tag_groups: action.tag_groups,
        booking_policies: action.booking_policies,
        cancellation_policies: action.cancellation_policies,
        venue_interactive_floorplan_images: action.venue_interactive_floorplan_images,
      }
    default:
      return state
  }
}

export default venueConfig
