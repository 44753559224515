import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  selectBookingPolicyTextMap,
  selectCancellationPolicyTextMap,
  selectEventInventoryBookingPolicyOptions,
  selectEventInventoryCancellationPolicyOptions,
} from 'mgr/events/selectors/eventSelectors'
import {
  saveInventory,
  loadInventory,
  updateInventory,
  deleteInventory,
  handleAdditionalFee,
  handleAdditionalFeeTax,
  handleRadioUpdate,
  toggleHidden,
  checkHandler,
  tieringCheckHandler,
  taxCheckHandler,
  toggleGuestCheckout,
  toggleEnablePromocodes,
  toggleUpsellsDisplay,
  selectUpsell,
  toggleTiering,
  changeInventoryBookingPolicyType,
  changeInventoryBookingPolicy,
  changeInventoryCancellationPolicyType,
  changeInventoryCancellationPolicy,
  changeSelectedTags,
} from '../actions/inventory'
import EditInventory from '../components/EditInventory'

class InventoryEditor extends Component {
  constructor(props) {
    super(props)

    this.onToggleTiering = this.onToggleTiering.bind(this)
  }

  componentWillMount() {
    this.debouncePriceUpdateHandler = _.debounce(e => {
      // Format field to remove unnecessary characters
      e.target.value = Number(e.target.value.replace(/[^0-9\.]+/g, ''))
      this.props.updateInventory(e)
    }, 500)
  }

  componentWillUnmount() {}

  onToggleTiering(_, value) {
    const { eventId } = this.props
    const eventInventories = this.props.eventListData.toJS().filter(item => item.id === eventId)[0].inventories
    this.props.toggleTiering(value, eventInventories)
  }

  render() {
    return (
      <EditInventory
        debouncePriceUpdateHandler={this.debouncePriceUpdateHandler}
        eventId={this.props.eventId}
        inventoryId={this.props.inventoryId}
        eventListData={this.props.eventListData}
        seatingAreas={this.props.seatingAreas}
        displayUpsellsSelector={this.props.displayUpsellsSelector}
        upsellsSelectMap={this.props.upsellsSelectMap}
        loadInventory={this.props.loadInventory}
        saveInventory={this.props.saveInventory}
        updateInventory={this.props.updateInventory}
        handleAdditionalFee={this.props.handleAdditionalFee}
        handleAdditionalFeeTax={this.props.handleAdditionalFeeTax}
        handleRadioUpdate={this.props.handleRadioUpdate}
        timeOptions={this.props.timeOptions}
        checkHandler={this.props.checkHandler}
        tieringCheckHandler={this.props.tieringCheckHandler}
        taxCheckHandler={this.props.taxCheckHandler}
        toggleHidden={this.props.toggleHidden}
        toggleGuestCheckout={this.props.toggleGuestCheckout}
        toggleEnablePromocodes={this.props.toggleEnablePromocodes}
        toggleUpsellsDisplay={this.props.toggleUpsellsDisplay}
        selectUpsell={this.props.selectUpsell}
        onToggleTiering={this.onToggleTiering}
        defaultBookingPolicy={this.props.defaultBookingPolicy}
        defaultCancellationPolicy={this.props.defaultCancellationPolicy}
        changeInventoryBookingPolicyType={this.props.changeInventoryBookingPolicyType}
        changeInventoryBookingPolicy={this.props.changeInventoryBookingPolicy}
        changeInventoryCancellationPolicyType={this.props.changeInventoryCancellationPolicyType}
        changeInventoryCancellationPolicy={this.props.changeInventoryCancellationPolicy}
        bookingPolicyOptions={this.props.bookingPolicyOptions}
        cancellationPolicyOptions={this.props.cancellationPolicyOptions}
        bookingPolicyTextMap={this.props.bookingPolicyTextMap}
        cancellationPolicyTextMap={this.props.cancellationPolicyTextMap}
        minPriceEnabled={this.props.minPriceEnabled}
        tagGroups={this.props.tagGroups}
        venueId={this.props.venueId}
        changeSelectedTags={this.props.changeSelectedTags}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  inventoryId: state.inventoryId,
  eventListData: state.eventListData,
  seatingAreas: state.seatingAreas,
  displayUpsellsSelector: state.upsells.get('addUpsell'),
  upsellsSelectMap: state.upsells.get('selectMap').toJS(),
  defaultBookingPolicy: state.venueConfig.default_booking_policy,
  defaultCancellationPolicy: state.venueConfig.default_cancellation_policy,
  bookingPolicyOptions: selectEventInventoryBookingPolicyOptions(state),
  cancellationPolicyOptions: selectEventInventoryCancellationPolicyOptions(state),
  bookingPolicyTextMap: selectBookingPolicyTextMap(state),
  cancellationPolicyTextMap: selectCancellationPolicyTextMap(state),
  minPriceEnabled: state.venueConfig.min_price_enabled,
  tagGroups: state.venueConfig.tag_groups,
  venueId: state.venueId,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  // load null to return to event
  loadInventory: () => {
    dispatch(deleteInventory(ownProps.eventId, 'new'))
    dispatch(loadInventory(null))
  },
  saveInventory: id => {
    dispatch(saveInventory(ownProps.venueId, ownProps.eventId, ownProps.inventoryId, id))
  },
  updateInventory: e => {
    dispatch(updateInventory(ownProps.eventId, ownProps.inventoryId, e))
  },
  checkHandler: e => {
    dispatch(checkHandler(ownProps.eventId, ownProps.inventoryId, e))
  },
  tieringCheckHandler: e => {
    dispatch(tieringCheckHandler(ownProps.eventId, ownProps.inventoryId, e))
  },
  taxCheckHandler: (e, taxGroups) => {
    dispatch(taxCheckHandler(ownProps.eventId, ownProps.inventoryId, e, taxGroups))
  },
  handleAdditionalFee: e => {
    dispatch(handleAdditionalFee(ownProps.eventId, ownProps.inventoryId, e))
  },
  handleAdditionalFeeTax: (e, taxGroups) => {
    dispatch(handleAdditionalFeeTax(ownProps.eventId, ownProps.inventoryId, e, taxGroups))
  },
  handleRadioUpdate: e => {
    dispatch(handleRadioUpdate(ownProps.eventId, ownProps.inventoryId, e))
  },
  changeInventoryBookingPolicyType: e => {
    dispatch(changeInventoryBookingPolicyType(ownProps.eventId, ownProps.inventoryId, e))
  },
  changeInventoryBookingPolicy: e => {
    dispatch(changeInventoryBookingPolicy(ownProps.eventId, ownProps.inventoryId, e))
  },
  changeInventoryCancellationPolicyType: e => {
    dispatch(changeInventoryCancellationPolicyType(ownProps.eventId, ownProps.inventoryId, e))
  },
  changeInventoryCancellationPolicy: e => {
    dispatch(changeInventoryCancellationPolicy(ownProps.eventId, ownProps.inventoryId, e))
  },
  toggleHidden: (_, value) => {
    dispatch(toggleHidden(ownProps.eventId, ownProps.inventoryId, value))
  },
  toggleGuestCheckout: (_, value) => {
    dispatch(toggleGuestCheckout(ownProps.eventId, ownProps.inventoryId, value))
  },
  toggleEnablePromocodes: (_, value) => {
    dispatch(toggleEnablePromocodes(ownProps.eventId, ownProps.inventoryId, value))
  },
  toggleTiering: (value, eventInventories) => {
    dispatch(toggleTiering(ownProps.eventId, ownProps.inventoryId, value, eventInventories))
  },
  toggleUpsellsDisplay: value => {
    dispatch(toggleUpsellsDisplay(value))
  },
  selectUpsell: selectMap => {
    dispatch(selectUpsell(selectMap))
  },
  changeSelectedTags: value => {
    dispatch(changeSelectedTags(ownProps.eventId, ownProps.inventoryId, value))
  },
})

InventoryEditor = connect(mapStateToProps, mapDispatchToProps)(InventoryEditor)

export default InventoryEditor
