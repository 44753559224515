import { areYouSure, shadow, sureControls } from 'mgr/events/assets/styles/general'
import ControlBar from 'mgr/events/components/ControlBar'
import type { CSSProperties } from 'react'

interface StartDateConfirmationBox {
  cancelAction: () => void
  saveAction: () => void
  saveLock: boolean
}

function StartDateConfirmationBox({ cancelAction, saveAction, saveLock }: StartDateConfirmationBox) {
  return (
    <div>
      <div style={shadow as CSSProperties} />
      <div style={areYouSure as CSSProperties}>
        Are you sure you want to change the start date of this event? Already purchased inventory will remain linked to this event, but will
        not be moved to the new start date.
        <ControlBar cancelAction={cancelAction} saveAction={saveAction} saveText="Save" addStyles={sureControls} saveLock={saveLock} />
      </div>
    </div>
  )
}

export default StartDateConfirmationBox
