// Constant action types for event management

export const ADD_EVENT = 'ADD_EVENT'
export const LOAD_EVENT = 'LOAD_EVENT'
export const LOAD_EVENTS = 'LOAD_EVENTS'
export const IS_LOADING_STATE = 'IS_LOADING_STATE'
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS'
export const LOAD_EVENT_SALES_SUCCESS = 'LOAD_EVENT_SALES_SUCCESS'
export const LOAD_EVENT_INVENTORIES_SUCCESS = 'LOAD_EVENT_INVENTORIES_SUCCESS'
export const LOAD_SEATING_AREAS_SUCCESS = 'LOAD_SEATING_AREAS_SUCCESS'
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS'
export const DELETE_EVENT_CONFIRM = 'DELETE_EVENT_CONFIRM '
export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const CLONE_EVENT = 'CLONE_EVENT'
export const LINK_EVENT = 'LINK_EVENT'
export const EVENT_LINK_COPIED = 'EVENT_LINK_COPIED'
export const UPDATE_DAYS = 'UPDATE_DAYS'
export const TOGGLE_RECURS = 'TOGGLE_RECURS'
export const ADD_INVENTORY = 'ADD_INVENTORY'
export const LOAD_INVENTORY = 'LOAD_INVENTORY'
export const SAVE_INVENTORY = 'SAVE_INVENTORY'
export const SAVE_INVENTORY_SUCCESS = 'SAVE_INVENTORY_SUCCESS'
export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS'
export const DELETE_INVENTORY_CONFIRM = 'DELETE_INVENTORY_CONFIRM'
export const DELETE_INVENTORY = 'DELETE_INVENTORY'
export const CLONE_INVENTORY = 'CLONE_INVENTORY'
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY'
export const UPLOAD_PHOTO_PREVIEW = 'UPLOAD_PHOTO_PREVIEW'
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'
export const UPDATE_PHOTO_TITLE = 'UPDATE_PHOTO_TITLE'
export const CROP_PHOTO = 'CROP_PHOTO'
export const CROPPING = 'CROPPING'
export const CROPPING_SAVE = 'CROPPING_SAVE'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const EXCLUDE_DATE_EVENT_SUCCESS = 'EXCLUDE_DATE_EVENT_SUCCESS'
export const TOGGLE_HIDE_EVENT = 'TOGGLE_HIDE_EVENT'
export const CHANGE_RECURRENCE_FREQUENCY = 'CHANGE_RECURRENCE_FREQUENCY'
export const ADJUST_TIME_DISPLAY = 'ADJUST_TIME_DISPLAY'
export const TOGGLE_HIDE_INVENTORY = 'TOGGLE_HIDE_INVENTORY'
export const TOGGLE_UPSELLS_DISPLAY = 'TOGGLE_UPSELLS_DISPLAY'
export const SELECT_UPSELL = 'SELECT_UPSELL'
export const LOAD_UPSELL = 'LOAD_UPSELL'
export const CHANGE_BOOKING_POLICY_TYPE = 'CHANGE_BOOKING_POLICY_TYPE'
export const CHANGE_BOOKING_POLICY = 'CHANGE_BOOKING_POLICY'
export const CHANGE_CANCELLATION_POLICY_TYPE = 'CHANGE_CANCELLATION_POLICY_TYPE'
export const CHANGE_CANCELLATION_POLICY = 'CHANGE_CANCELLATION_POLICY'
export const CHANGE_INVENTORY_BOOKING_POLICY_TYPE = 'CHANGE_INVENTORY_BOOKING_POLICY_TYPE'
export const CHANGE_INVENTORY_BOOKING_POLICY = 'CHANGE_INVENTORY_BOOKING_POLICY'
export const CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE = 'CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE'
export const CHANGE_INVENTORY_CANCELLATION_POLICY = 'CHANGE_INVENTORY_CANCELLATION_POLICY'
export const TOGGLE_3D_FLOORPLAN = 'TOGGLE_3D_FLOORPLAN'
export const LOAD_VENUE_CONFIG = 'LOAD_VENUE_CONFIG'
export const CHANGE_SELECTED_TAGS = 'CHANGE_SELECTED_TAGS'
export const UPDATE_VALID_LINK = 'UPDATE_VALID_LINK'
export const RESET_EVENT = 'RESET_EVENT'
export const UPDATE_EDITING_EVENT_START_DATE = 'UPDATE_EDITING_EVENT_START_DATE'
export const LOAD_EVENT_DESCRIPTION_DATA = 'LOAD_EVENT_DESCRIPTION_DATA'
export const LOAD_EVENT_IMAGE_DATA = 'LOAD_EVENT_IMAGE_DATA'
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'
