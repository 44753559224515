import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateEventForInventory } from '../actions/events'
import { loadInventory, addInventory, deleteInventoryConfirm, deleteInventory, cloneInventory } from '../actions/inventory'
import ListInventory from '../components/ListInventory'

class InventoryList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ListInventory
        eventId={this.props.eventId}
        venueId={this.props.venueId}
        eventListData={this.props.eventListData}
        seatingAreas={this.props.seatingAreas}
        inventoryId={this.props.inventoryId}
        onEditClick={this.props.loadInventory}
        onAddClick={this.props.addInventory}
        deleteInventory={this.props.deleteInventory}
        deleteInventoryConfirm={this.props.deleteInventoryConfirm}
        cloneInventory={this.props.cloneInventory}
        ux={this.props.ux}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventListData: state.eventListData.toJS(),
  seatingAreas: state.seatingAreas,
  inventoryId: state.inventoryId,
  eventId: state.currentEvent.id,
  ux: state.ux,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadInventory: id => {
    dispatch(loadInventory(id))
  },
  addInventory: () => {
    dispatch(updateEventForInventory(ownProps.venueId, ownProps.eventId))
  },
  deleteInventory: id => {
    dispatch(deleteInventory(ownProps.eventId, id))
  },
  deleteInventoryConfirm: id => {
    dispatch(deleteInventoryConfirm(id))
  },
  cloneInventory: id => {
    dispatch(cloneInventory(ownProps.eventId, id))
    dispatch(loadInventory('new'))
  },
})

InventoryList = connect(mapStateToProps, mapDispatchToProps)(InventoryList)

export default InventoryList
