import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { getExperiences } from 'mgr/lib/services/ExperiencesServices'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'

export const tryGetExperiences = venueOrId => dispatch => {
  const id = Object.hasOwn(venueOrId, 'id') ? venueOrId.id : venueOrId

  dispatch({
    type: ActionTypes.LOAD_EXPERIENCES_START,
  })

  return getExperiences(id, { include_static_links: true })
    .then(response => {
      dispatch({
        type: ActionTypes.LOAD_EXPERIENCES_SUCCESS,
        data: {
          ...response.data,
          results: response.data.results.sort((a, b) => a.sort_order - b.sort_order),
        },
      })
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage('Unable to fetch your experiences, please try again later or contact customer support.'))

      dispatch({
        type: ActionTypes.LOAD_EXPERIENCES_FAIL,
      })

      return e
    })
}

export const toggleExperienceLinkModal = linkModalID => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_EXPERIENCE_LINK_MODAL,
    linkModalID,
  })
  window.metric.track('Offers.ViewExperienceLinks')
}
