import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadEvent, addEvent, deleteEventConfirm, deleteEvent, linkEvent, cloneEvent, showRecurs } from '../actions/events'
import EventListComponent from '../components/EventList'

class EventList extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <EventListComponent
        onListClick={this.props.loadEvent}
        eventList={this.props.eventList}
        addEvent={this.props.addEvent}
        deleteEventConfirm={this.props.deleteEventConfirm}
        isLoading={this.props.isLoading}
        deleteEvent={this.props.deleteEvent}
        cloneEvent={this.props.cloneEvent}
        showRecurs={this.props.showRecurs}
        linkEvent={this.props.linkEvent}
        excludeDateHandler={this.props.excludeDateHandler}
        venueId={this.props.venueId}
        ux={this.props.ux}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventList: state.eventListData,
  ux: state.ux,
  isLoading: state.isLoading.get('isLoading'),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadEvent: id => {
    dispatch(loadEvent(id))
  },
  addEvent: venueId => {
    dispatch(addEvent(venueId))
    dispatch(loadEvent('new'))
  },
  deleteEvent: id => {
    dispatch(deleteEvent(id))
  },
  cloneEvent: id => {
    dispatch(cloneEvent(id))
    dispatch(loadEvent('new'))
  },
  deleteEventConfirm: id => {
    dispatch(deleteEventConfirm(id))
  },
  linkEvent: (id, linkId) => {
    dispatch(linkEvent(id, linkId))
  },
  showRecurs: id => {
    dispatch(showRecurs(id))
  },
})

EventList = connect(mapStateToProps, mapDispatchToProps)(EventList)

export default EventList
