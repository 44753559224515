import React from 'react'
import { Input, Select } from '../../../../common/FormElement'
import { dateTime, date, time, inputDate, inputTime } from '../assets/styles/eventEditor'
import DateWrapper from './DateWrapper'

// need times, current day, etc., + calendar widgety thingy
const DateTime = ({ name, title, onChangeDate, onChangeTime, timeOptions, data }) => (
  <div className="date-time" style={dateTime}>
    <DateWrapper label={title} onChange={onChangeDate} rawDate={data[`${name}_date`]} name={`${name}_date`} styles={$.extend({}, date)} />

    <Select
      name={`${name}_time`}
      value={data[`${name}_time`]}
      options={timeOptions}
      style={time}
      preInputStyle={{ width: 100 }}
      inputCss={inputTime}
      onChangeHandler={onChangeTime}
      MEDIA_URL={Pmp.Settings.MEDIA_URL}
    />
  </div>
)

export default DateTime
