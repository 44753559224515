import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  selectEventBookingPolicyOptions,
  selectEventCancellationPolicyOptions,
  selectBookingPolicyTextMap,
  selectCancellationPolicyTextMap,
} from 'mgr/events/selectors/eventSelectors'
import { tryGetExperiences } from 'mgr/pages/single-venue/marketing/actions/Experiences'
import {
  loadEvent,
  saveEvent,
  updateEvent,
  deleteEvent,
  updateDescription,
  toggleRecurrence,
  toggleHidden,
  togglePrivate,
  toggle3dFloorplan,
  updateLinkedExperience,
  changeBookingPolicyType,
  changeBookingPolicy,
  changeCancellationPolicyType,
  changeCancellationPolicy,
  cancelEdit,
  updateEditingEventStartDate,
} from '../actions/events'
import EditEvent from '../components/EditEvent'

class EventEditor extends Component {
  componentDidMount() {
    const { tryGetExperiences, venueId } = this.props

    tryGetExperiences(venueId)
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <EditEvent
        eventId={this.props.eventId}
        eventListData={this.props.eventListData}
        toggleRecurrence={this.props.toggleRecurrence}
        loadEvent={this.props.loadEvent}
        cancelEdit={this.props.cancelEdit}
        saveEvent={this.props.saveEvent}
        venueId={this.props.venueId}
        updateEvent={this.props.updateEvent}
        updateDate={this.props.updateDate}
        timeOptions={this.props.timeOptions}
        updateDescription={this.props.updateDescription}
        toggleHidden={this.props.toggleHidden}
        togglePrivate={this.props.togglePrivate}
        toggle3dFloorplan={this.props.toggle3dFloorplan}
        changeBookingPolicyType={this.props.changeBookingPolicyType}
        changeBookingPolicy={this.props.changeBookingPolicy}
        changeCancellationPolicyType={this.props.changeCancellationPolicyType}
        changeCancellationPolicy={this.props.changeCancellationPolicy}
        ux={this.props.ux}
        experiences={this.props.experiences.experiences}
        updateLinkedExperience={this.props.updateLinkedExperience}
        defaultBookingPolicy={this.props.defaultBookingPolicy}
        defaultCancellationPolicy={this.props.defaultCancellationPolicy}
        bookingPolicyOptions={this.props.bookingPolicyOptions}
        cancellationPolicyOptions={this.props.cancellationPolicyOptions}
        bookingPolicyTextMap={this.props.bookingPolicyTextMap}
        cancellationPolicyTextMap={this.props.cancellationPolicyTextMap}
        startConfirmEventStartDate={this.props.startConfirmEventStartDate}
        endConfirmEventStartDate={this.props.endConfirmEventStartDate}
      />
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  eventListData: state.eventListData.toJS(),
  ux: state.ux.toJS(),
  experiences: state.experiences.experiences,
  defaultBookingPolicy: state.venueConfig.default_booking_policy,
  defaultCancellationPolicy: state.venueConfig.default_cancellation_policy,
  bookingPolicyOptions: selectEventBookingPolicyOptions(state),
  cancellationPolicyOptions: selectEventCancellationPolicyOptions(state),
  bookingPolicyTextMap: selectBookingPolicyTextMap(state),
  cancellationPolicyTextMap: selectCancellationPolicyTextMap(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  // load null to return to list
  loadEvent: () => {
    dispatch(deleteEvent('new'))
    dispatch(updateEditingEventStartDate(false))
    dispatch(loadEvent(null))
  },
  cancelEdit: () => {
    dispatch(updateEditingEventStartDate(false))
    dispatch(cancelEdit())
  },
  saveEvent: e => {
    e.preventDefault()
    dispatch(saveEvent(ownProps.venueId, ownProps.eventId))
    // upon successful save, we want to reset the event start date functionality
    dispatch(updateEditingEventStartDate(false))
  },
  updateEvent: e => {
    dispatch(updateEvent(ownProps.eventId, e))
  },
  changeBookingPolicyType: e => {
    dispatch(changeBookingPolicyType(ownProps.eventId, e))
  },
  changeBookingPolicy: e => {
    dispatch(changeBookingPolicy(ownProps.eventId, e))
  },
  changeCancellationPolicyType: e => {
    dispatch(changeCancellationPolicyType(ownProps.eventId, e))
  },
  changeCancellationPolicy: e => {
    dispatch(changeCancellationPolicy(ownProps.eventId, e))
  },
  updateDescription: html => {
    dispatch(updateDescription(ownProps.eventId, html))
  },
  updateLinkedExperience: experienceId => {
    dispatch(updateLinkedExperience(ownProps.eventId, experienceId))
  },
  toggleRecurrence: e => {
    dispatch(toggleRecurrence(ownProps.eventId, e))
  },
  toggleHidden: (_, value) => {
    dispatch(toggleHidden(ownProps.eventId, value))
  },
  togglePrivate: _ => {
    dispatch(togglePrivate(ownProps.eventId))
  },
  toggle3dFloorplan: () => {
    dispatch(toggle3dFloorplan())
  },
  tryGetExperiences: venueId => {
    dispatch(tryGetExperiences(venueId))
  },
  updateDate: (m, name) => {
    // hackin around
    const e = {
      target: {
        name,
        value: m.format('MM/DD/YYYY'),
      },
    }
    if (name === 'start_date') {
      // we only want the popup warning flow for start date modifications
      dispatch(updateEditingEventStartDate(true))
    }
    dispatch(updateEvent(ownProps.eventId, e))
  },
  endEditEventStartDate: () => {
    dispatch(updateEditingEventStartDate(false))
  },
})

EventEditor = connect(mapStateToProps, mapDispatchToProps)(EventEditor)

export default EventEditor
