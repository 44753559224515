export const POLICY_TYPE = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
  EVENT: 'EVENT',
}

export const POLICY_TYPE_DISPLAY = {
  DEFAULT: 'Default Policy',
  CUSTOM: 'Custom Policy',
  EVENT: 'Event Policy',
}

export const isCustomPolicy = (policyType: string): boolean => policyType === POLICY_TYPE.CUSTOM
