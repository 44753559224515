import { LOAD_EVENTS_SUCCESS } from '../actions/ActionTypes'

const venueId = (state = null, action) => {
  switch (action.type) {
    case LOAD_EVENTS_SUCCESS:
      return action.venueId
    default:
      return state
  }
}

export default venueId
