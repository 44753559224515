const action = {
  backgroundColor: '#f1f1f1',
  backgroundSize: '15px 15px',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ccc',
  borderRadius: '3px',
  cursor: 'pointer',
  display: 'inline-block',
  height: 35,
  marginLeft: 5,
  marginTop: 2,
  width: 31,
}

export const actionClone = { ...action, backgroundImage: `url(${MEDIA_URL}images/eventmanager/clone.png)` }

export const actionEventLink = { ...action, backgroundImage: `url(${MEDIA_URL}images/eventmanager/link_icon.png)` }

export const actionList = { ...action, backgroundImage: `url(${MEDIA_URL}images/eventmanager/list.png)` }

export const actionEdit = { ...action, backgroundImage: `url(${MEDIA_URL}images/eventmanager/edit.png)` }

export const actionDelete = { ...action, backgroundImage: `url(${MEDIA_URL}images/eventmanager/delete.png)` }
