import React from 'react'
import { actionEdit, actionDelete } from '../assets/styles/buttons'
import * as styles from '../assets/styles/photos'
import Dropper from '../containers/Dropper'

const BannerUpload = ({ photo_id, photo, clickEdit, clickDelete }) => {
  let style = { ...styles.hasPhoto, ...styles.bigPhoto }

  let crop = {}

  if (photo && photo.cropData) {
    const widthDiff = Number(photo.cropData.width) / 100
    const heightDiff = Number(photo.cropData.height) / 100
    crop = {
      width: `${100 / widthDiff}%`,
      top: `${-1 * (Number(photo.cropData.y) / heightDiff)}%`,
      left: `${-1 * (Number(photo.cropData.x) / widthDiff)}%`,
    }
  }

  const imgStyle = { ...styles.photoStyle, ...crop }

  // will have to merge cropdata to photostyles
  let content = (
    <div style={style}>
      <img src={photo.url} style={imgStyle} />
      <div style={styles.photoActions}>
        <p onClick={clickEdit.bind(null, photo_id)} style={$.extend({}, actionEdit, styles.actionAdjust)} />
        <p onClick={clickDelete.bind(null, photo_id)} style={$.extend({}, actionDelete, styles.actionAdjust)} />
      </div>
    </div>
  )

  if (!photo.url) {
    style = { ...styles.emptyPhoto, ...styles.bigPhoto }
    content = <Dropper id={photo_id} style={style} />
  }

  return (
    <div id="banner-upload" style={{ position: 'relative', float: 'left' }}>
      {content}
      <p style={{ ...styles.photoLabel, width: 400 }}>{photo.label}</p>
    </div>
  )
}

export default BannerUpload
