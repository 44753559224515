import { LOAD_INVENTORY } from '../actions/ActionTypes'

// This should default to null, temp for design

const inventoryId = (state = null, action) => {
  switch (action.type) {
    case LOAD_INVENTORY:
      return action.id
    default:
      return state
  }
}

export default inventoryId
