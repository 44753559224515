import moment from 'moment-timezone'
import React from 'react'
import { CheckBox, Select } from '../../../../common/FormElement'
import * as eventStyles from '../assets/styles/eventEditor'
import * as styles from '../assets/styles/eventRecurrence'
import { getRecurs } from '../utils/time'
import DateWrapper from './DateWrapper'

const RecurringEvent = ({ eventId, eventListData, updateDays, updateDate, timeOptions, updateEvent }) => {
  const data = eventListData.find(item => item.id === eventId)

  if (!data || !data.is_recurring) {
    return <div />
  }

  const frequencyOptions = {
    '': '',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
  }

  const daysOfWeek = []
  const weekMap = {
    Su: 'Sunday',
    M: 'Monday',
    Tu: 'Tuesday',
    W: 'Wednesday',
    Th: 'Thursday',
    F: 'Friday',
    Sa: 'Saturday',
  }

  let checked = false
  for (const day in weekMap) {
    const name = weekMap[day]
    checked = data.day_of_week.indexOf(name) != -1
    daysOfWeek.push(
      <CheckBox
        key={`recur_day_${day}`}
        labelText={day}
        name={name}
        value="1"
        style={styles.dowItem}
        postLabelStyle={styles.dowLabel}
        onClickHandler={updateDays}
        checked={checked}
      />
    )
  }

  const s_date = moment(data.start_date)
  const e_date = moment(data.end_date)
  const event_count = getRecurs(data.recurring_type, data.start_date, data.end_date, data.day_of_week)

  const calcedDisplay = [event_count, 'dates starting', s_date.format('MMM Do, YYYY'), 'through', e_date.format('MMM Do, YYYY')].join(' ')

  const venue_today = document.getElementById('today_date_url').value
  const venue_today_moment = moment(venue_today)

  return (
    <div id="recurring-interface">
      <div id="recurring-settings">
        <div className="modal-content" style={styles.content}>
          <div>
            <Select
              labelText="How often does this event recur?"
              name="recurring_type"
              options={frequencyOptions}
              value={data.recurring_type}
              onChangeHandler={updateEvent}
              MEDIA_URL={Pmp.Settings.MEDIA_URL}
            />
          </div>
          {data.recurring_type == 'WEEKLY' ? (
            <div className="dow-picker" style={styles.dow}>
              <p className="group-label">Which days of the week?</p>
              {daysOfWeek}
            </div>
          ) : null}

          <div style={styles.times}>
            <Select
              labelText="Event start time"
              name="start_time"
              value={data.start_time}
              options={timeOptions}
              style={$.extend({}, eventStyles.time, styles.dtAdjustment)}
              inputCss={eventStyles.inputTime}
              preInputStyle={{ width: 110 }}
              preLabelStyle={{ width: 110 }}
              onChangeHandler={updateEvent}
              MEDIA_URL={Pmp.Settings.MEDIA_URL}
            />
            <Select
              labelText="Event end time"
              name="end_time"
              value={data.end_time}
              options={timeOptions}
              style={$.extend({}, eventStyles.time, styles.dtAdjustment)}
              inputCss={eventStyles.inputTime}
              preInputStyle={{ width: 110 }}
              onChangeHandler={updateEvent}
              MEDIA_URL={Pmp.Settings.MEDIA_URL}
            />
          </div>

          <div style={styles.dates}>
            <DateWrapper
              label="Start recurrence"
              onChange={updateDate}
              rawDate={moment(data.start_date) < venue_today_moment ? venue_today_moment.format('MM/DD/YYYY') : data.start_date}
              name="start_date"
              styles={$.extend({}, eventStyles.date, styles.dtAdjustment)}
            />
            <DateWrapper
              label="End recurrence (1 year max)"
              onChange={updateDate}
              rawDate={data.end_date}
              name="end_date"
              styles={$.extend({}, eventStyles.date, styles.dtAdjustment)}
            />
          </div>
          <div style={styles.calced}>{calcedDisplay}</div>
        </div>
      </div>
    </div>
  )
}

export default RecurringEvent
