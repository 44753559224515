// Default template for new event

import { POLICY_TYPE } from 'mgr/events/utils/PolicyType'

const date = () => {
  const venue_today = document.getElementById('today_date_url').value
  const d = new Date(venue_today.replace(/-/g, '/'))
  const mm = d.getMonth() + 1
  const dd = d.getDate()
  return [mm > 9 ? mm : `0${mm}`, dd > 9 ? dd : `0${dd}`, d.getFullYear()].join('/')
}

const eventStructure = {
  id: 'new',

  name: '',
  hide_event: false,
  private_event: false,
  is_recurring: false,
  past: false,
  start_date: date(),
  end_date: date(),
  start_time: '7:00 PM',
  end_time: '10:00 PM',
  shift_categories: [],
  excluded_dates: [],
  policy_dict: {},
  dates_remaining: null,
  photo_map: {
    1: { label: '', url: null, cropData: null },
    2: { label: '', url: null, cropData: null },
    3: { label: '', url: null, cropData: null },
    4: { label: '', url: null, cropData: null },
    5: { label: '', url: null, cropData: null },
    6: { label: '', url: null, cropData: null },
    7: { label: '', url: null, cropData: null },
  },

  recurring_type: 'ONCE',
  day_of_week: [],
  description: '',
  inventories: [],
  sales_data: {
    tickets_sold: 0,
    tickets_total: 0,
    revenue: '',
    sales: [],
  },
  selected_booking_policy: POLICY_TYPE.DEFAULT,
  custom_booking_policy: null,
  selected_cancellation_policy: POLICY_TYPE.DEFAULT,
  custom_cancellation_policy: null,
}

export default eventStructure
