import { POLICY_TYPE } from 'mgr/events/utils/PolicyType'

// Default structure for new inventory items

const inventoryStructure = {
  id: 'new',
  event_id: '',
  inventory_type: 'RESERVATION',
  inventory_name: '',
  price: '',
  comp_text: 'Free',
  entry_per_reservation: '',
  total_quantity: '',
  total_purchased: '',
  min_quantity: '1',
  max_quantity: '10',
  seating_area: '',
  charge_tax: false,
  charge_gratuity: false,
  charge_additional_fee: false,
  charge_additional_fee_tax: false,
  additional_fee_amount: 0,
  additional_fee_label: 'Admin fee',
  additional_fee_amount_type: 'PER_ORDER',
  additional_fee_tax_id: '',
  gratuity_amount_type: 'DEFAULT_FIXED',
  gratuity_amount: document.getElementById('default_gratuity').value,
  handle_fee: true,
  sale_start_num: '30',
  sale_start_type: 'DAYS',
  sale_start_hour: '12:00 PM',
  sale_end_num: '1',
  sale_end_type: 'HOURS',
  sale_end_hour: 'EVENT_START_TIME',
  total_price: '',
  hide_inventory: false,
  guest_checkout: true,
  enable_promocodes: true,
  upsell_categories: [],
  hide_tiering: true,
  tier_list: [],
  selected_booking_policy: POLICY_TYPE.EVENT,
  custom_booking_policy: null,
  selected_cancellation_policy: POLICY_TYPE.EVENT,
  custom_cancellation_policy: null,
  min_price: null,
  selected_tags: [],
  require_gratuity_charge: false,
  apply_service_charge: false,
  service_charge_type: 'DEFAULT_SERVICE_CHARGE',
  service_charge_amount: 0,
}

export default inventoryStructure
