import React from 'react'
import { Input, Select } from '../../../../common/FormElement'
import * as styles from '../assets/styles/inventoryEditor'

// need times, current day, etc., + calendar widgety thingy
const RelativeTime = ({ name, label, data, event_start, timeOptions, updateInventory }) => {
  const modifiedTimeOptions =
    data[`${name}_type`] == 'HOURS'
      ? {
          EVENT_START_TIME: 'Event start time',
          EVENT_END_TIME: 'Event end time',
        }
      : {
          EVENT_START_TIME: 'Event start time',
          EVENT_END_TIME: 'Event end time',
          ...timeOptions,
        }
  const priorOptions = {
    DAYS: 'Days prior',
    HOURS: 'Hours prior',
    MONTHS: 'Months prior',
  }

  const _width = 140
  const selectAdjust = {
    width: _width,
    marginTop: 28,
  }

  const calced = ['Next event']

  return (
    <div className="relative-time" style={{ float: 'left', marginRight: 15 }}>
      <Input
        labelText={label}
        name={`${name}_num`}
        value={data[`${name}_num`]}
        inputCss={{ width: 30 }}
        style={styles.rowItems}
        onChangeHandler={updateInventory}
        validator_type="int_positive"
        validator_message="Enter a number"
      />
      <Select
        name={`${name}_type`}
        value={data[`${name}_type`]}
        options={priorOptions}
        preInputStyle={{ width: _width }}
        style={$.extend({}, styles.rowItems, selectAdjust)}
        onChangeHandler={updateInventory}
        MEDIA_URL={Pmp.Settings.MEDIA_URL}
      />
      <span className="time-divide" style={styles.timeDivide}>
        at
      </span>
      <Select
        name={`${name}_hour`}
        value={data[`${name}_hour`]}
        options={modifiedTimeOptions}
        preInputStyle={{ width: _width }}
        style={$.extend({}, styles.rowItems, selectAdjust)}
        onChangeHandler={updateInventory}
        MEDIA_URL={Pmp.Settings.MEDIA_URL}
      />
    </div>
  )
  /* Non trivial, punting <p className="calculated-time" style={styles.calced}>Compose from state</p> */
}

export default RelativeTime
