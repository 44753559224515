import _ from 'lodash'
import { POLICY_TYPE, POLICY_TYPE_DISPLAY } from 'mgr/events/utils/PolicyType'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import type { EventPolicy } from '@sevenrooms/core/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const computePolicyParams = (state: any) => ({
  bookingPolicies: state.venueConfig.booking_policies,
  cancellationPolicies: state.venueConfig.cancellation_policies,
  defaultBookingPolicy: state.venueConfig.default_booking_policy,
  defaultCancellationPolicy: state.venueConfig.default_cancellation_policy,
})

const getPolicyBankOptions = (policies: EventPolicy[]): Record<string, string> =>
  _.reduce(policies, (options, policy) => ({ ...options, [policy.id]: policy.name }), {})

const buildPolicyTextMap = (policies: EventPolicy[], defaultPolicy: string): Record<string, string> => {
  const policyTextMap: Record<string, string> = _.reduce(
    policies,
    (options, policy) => ({ ...options, [policy.id]: policy.policy_text }),
    {}
  )
  policyTextMap[POLICY_TYPE.DEFAULT] = defaultPolicy
  return policyTextMap
}

const buildEventPolicyOptions = (policies: EventPolicy[]) => {
  const options = getPolicyBankOptions(policies)
  options[POLICY_TYPE.CUSTOM] = POLICY_TYPE_DISPLAY.CUSTOM
  return options
}

const buildEventInventoryPolicyOptions = (policies: EventPolicy[]) => {
  const options = getPolicyBankOptions(policies)
  options[POLICY_TYPE.EVENT] = POLICY_TYPE_DISPLAY.EVENT
  options[POLICY_TYPE.CUSTOM] = POLICY_TYPE_DISPLAY.CUSTOM
  return options
}

export const selectEventBookingPolicyOptions = createShallowEqualSelector(computePolicyParams, ({ bookingPolicies }) =>
  buildEventPolicyOptions(bookingPolicies)
)

export const selectEventCancellationPolicyOptions = createShallowEqualSelector(computePolicyParams, ({ cancellationPolicies }) =>
  buildEventPolicyOptions(cancellationPolicies)
)

export const selectEventInventoryBookingPolicyOptions = createShallowEqualSelector(computePolicyParams, ({ bookingPolicies }) =>
  buildEventInventoryPolicyOptions(bookingPolicies)
)

export const selectEventInventoryCancellationPolicyOptions = createShallowEqualSelector(computePolicyParams, ({ cancellationPolicies }) =>
  buildEventInventoryPolicyOptions(cancellationPolicies)
)

export const selectBookingPolicyTextMap = createShallowEqualSelector(computePolicyParams, ({ bookingPolicies, defaultBookingPolicy }) =>
  buildPolicyTextMap(bookingPolicies, defaultBookingPolicy)
)

export const selectCancellationPolicyTextMap = createShallowEqualSelector(
  computePolicyParams,
  ({ cancellationPolicies, defaultCancellationPolicy }) => buildPolicyTextMap(cancellationPolicies, defaultCancellationPolicy)
)
