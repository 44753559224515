export const bar = {
  background: '#fff',
  borderTop: '1px solid #dadada',
  bottom: 0,
  height: 54,
  position: 'fixed',
  right: 0,
  width: '100%',
  zIndex: 97,
}

export const modalBar = {
  position: 'absolute',
}

export const buttons = {
  float: 'right',
  margin: '7px 7px 0 0',
  width: 60,
}
