export const brandColor = '#347BAF'

export const headerStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

export const headerTextStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '60%',
}

export const shadow = {
  background: '#000',
  height: '100%',
  left: 0,
  opacity: 0.8,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1000,
}

export const note = {
  color: '#999',
  fontStyle: 'italic',
}

export const pad = {
  float: 'left',
  padding: '20px 20px 100px',
}

export const lightButton = {
  background: '#eee',
  color: '#888',
}

export const informationStyle = {
  background: '#ccc',
  borderRadius: 12,
  color: '#fff',
  display: 'inline-block',
  fontFamily: 'Times',
  fontSize: 10,
  fontWeight: 'bold',
  hieght: 12,
  lineHieght: 12,
  marginLeft: 5,
  textAlign: 'center',
  width: 12,
}

export const areYouSure = {
  background: '#fff',
  fontSize: '16px',
  left: '50%',
  marginLeft: -220,
  padding: 20,
  position: 'fixed',
  top: 200,
  width: 400,
  height: 100,
  zIndex: 1001,
}

export const sureControls = {
  height: 54,
  left: '50%',
  marginLeft: -220,
  top: 320,
  width: 440,
}
