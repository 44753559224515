import React from 'react'
import EventEditor from '../containers/EventEditor'
import EventList from '../containers/EventList'
import InventoryEditor from '../containers/InventoryEditor'

const Main = ({ venueId, eventId, inventoryId, loadEvent, isLoading, excludeDateHandler, timeOptions }) => {
  const view = (inventoryId, eventId, venueId) => {
    if (eventId) {
      let content = <EventEditor eventId={eventId} venueId={venueId} timeOptions={timeOptions} />
      if (inventoryId) {
        content = (
          <div>
            <EventEditor eventId={eventId} venueId={venueId} timeOptions={timeOptions} />
            <InventoryEditor eventId={eventId} inventoryId={inventoryId} venueId={venueId} timeOptions={timeOptions} />
          </div>
        )
      }
      return content
    }
    return <EventList onListClick={loadEvent} excludeDateHandler={excludeDateHandler} isLoading={isLoading} venueId={venueId} />
  }

  return (
    <div id="event-manager">
      <div id="main-area">{view(inventoryId, eventId, venueId)}</div>
    </div>
  )
}

export default Main
