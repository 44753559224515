import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import _ from 'underscore'
import Button from '../../../../common/Button'
import { Input } from '../../../../common/FormElement'
import { cropImage, updatePhotoTitle, closeCrop, saveCrop } from '../actions/events'
import * as buttonStyles from '../assets/styles/controlBar'
import { shadow } from '../assets/styles/general'
import { closer, eg } from '../assets/styles/inventoryEditor'
import { cropWrap } from '../assets/styles/photos'
import Header from '../components/Header'

class Cropper extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    const pid = this.props.ux.get('photoId')

    let message = 'Alternate images must be no larger than 1400 pixels wide.'
    let aspect = 1
    if (pid == 1) {
      message = 'For your banner, we recommend square images between 1500 and 2500 pixels wide (.png, .jpeg, and .gif files only).'
      aspect = 2
    }

    const note = { margin: '5px 10px 10px', ...eg }

    const that = this
    const data = this.props.eventListData.toJS().filter(item => item.id == that.props.eventId)[0].photo_map[pid]
    const src = data.url

    const crop = data.cropData || {
      x: 25,
      y: 25,
      width: 50,
      aspect,
    }

    if (!src) {
      return null
    }
    return (
      <div id="crop-interface">
        <div style={shadow} />
        <div style={cropWrap}>
          <Header title={data.label} />
          <div onClick={this.props.closeCrop} style={closer}>
            &times;
          </div>
          <ReactCrop src={src} crop={crop} onComplete={this.props.cropImage} />
          <div>
            <p style={note}>{message}</p>
            <form action="" className="styled" style={{ margin: '10px 10px 5px' }}>
              <Input
                name="photo_title"
                labelText="Description"
                value={data.label}
                onChangeHandler={this.props.updatePhotoTitle}
                inputCss={{ width: 362 }}
              />
            </form>
          </div>
          <div style={{ clear: 'both', paddingTop: 10 }}>
            <Button label="Save" onClick={this.props.saveCrop} style={{ width: 320, margin: '5px 10px' }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  eventId: state.currentEvent.id,
  eventListData: state.eventListData,
  ux: state.ux,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  cropImage: coords => {
    dispatch(cropImage(ownProps.eventId, ownProps.ux.photoId, coords))
  },
  updatePhotoTitle: e => {
    dispatch(updatePhotoTitle(ownProps.eventId, ownProps.ux.photoId, e))
  },
  closeCrop: () => {
    dispatch(closeCrop())
  },
  saveCrop: () => {
    dispatch(saveCrop(ownProps.eventId, ownProps.ux.photoId))
  },
})

Cropper = connect(mapStateToProps, mapDispatchToProps)(Cropper)

export default Cropper
